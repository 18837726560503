export const STAFF_INFO = 'staff_info';
export const LOGIN = 'login';
export const LOGIN_GOOGLE_LOGIN = 'login_google_login';
export const LOGIN_GOOGLE_SIGNUP = 'login_google_signup';
export const LOGOUT = 'logout';
export const SIGN_UP = 'sign_up';
export const DOWNLOAD_TRANSCRIPT = 'download_transcript';
export const GENERATE_TITLE = 'generate_title';
export const GENERATE_DESCRIPTION = 'generate_description';
export const UPLOAD_ANOTHER = 'upload_another';
export const FILE_DROP = 'file_drop';
export const TRANSCODE_ERROR = 'transcode_error';
export const LIMIT = 'limit';
export const FAIL_TEST_CAPABILITIES = 'fail_test_capabilities';
